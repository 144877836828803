<template>
    <div style="display: flex; flex-direction: column;height: 100%;">
        <!-- 视频信息 -->
        <div class="analysisFileContainer" v-if="videoInfo && videoInfo.VideoId">
            <div class="analysisFileLeftContainer">
                <img :src="anchorInfo.AnchorAvatar" class="analysisCompereImg">
                <div class="analysisCompereName">{{ anchorInfo.AnchorName }}</div>
            </div>
            <div class="analysisFileRightContainer">
                <div class="analysisFileRightItemContainer rightBorder">
                    <div>开始：</div>
                    <div>{{ videoInfo.StartTime.substring(0, 16) }}</div>
                </div>
                <div class="analysisFileRightItemContainer rightBorder">
                    <div>结束：</div>
                    <div>{{ videoInfo.EndTime.substring(0, 16) }}</div>
                </div>
                <div class="analysisFileRightItemContainer">
                    <div>时长：</div>
                    <div>{{ durationStr }}</div>
                </div>
            </div>
        </div>
        <!-- 上传的文件信息 -->
        <div class="analysisFileContainer" v-else>
            <div class="analysisFileLeftContainer">
                <img src="@/assets/imgs/video.png" class="analysisCompereImg">
                <div class="analysisCompereName">{{ fileInfo.fileName }}</div>
            </div>
            <div class="analysisFileRightContainer">
                <div class="analysisFileRightItemContainer rightBorder">
                    <div>上传时间：</div>
                    <div>{{ fileInfo.uploadTime.substring(0, 16) }}</div>
                </div>
                <div class="analysisFileRightItemContainer rightBorder">
                    <div>分析时间：</div>
                    <div>{{ fileInfo.analysisTime.substring(0, 16) }}</div>
                </div>
            </div>
        </div>

        <!-- 敏感词/关键词/语速汇总 -->
        <div class="wordsContainer">
            <div class="wordsItemContainer">
                <div class="wordsItemColorContainer">
                    <div class="wordsItemColor" style="background: pink;"></div>
                    <div class="wordsItemText">敏感词：{{ wordsInfo.sensitiveWordsNum }}次</div>
                </div>
                <div class="indiciaBtn" v-if="!wordsInfo.markSensitive" @click="markClick('sensitive', true)">点击标注
                </div>
                <div class="cancelIndiciaBtn" v-else @click="markClick('sensitive', false)">取消标注</div>
            </div>
            <div class="wordsItemContainer">
                <div class="wordsItemColorContainer">
                    <div class="wordsItemColor" style="background: paleturquoise;"></div>
                    <div class="wordsItemText">关键词：{{ wordsInfo.cruxWordsNum }}次</div>
                </div>
                <div class="indiciaBtn" v-if="!wordsInfo.markCrux" @click="markClick('crux', true)">点击标注</div>
                <div class="cancelIndiciaBtn" v-else @click="markClick('crux', false)">取消标注</div>
            </div>
            <div class="wordsItemContainer" v-if="videoInfo && videoInfo.VideoId">
                <div class="wordsItemColorContainer">
                    <div class="wordsItemText" v-if="!analysisChar">语速：**字/分钟</div>
                    <div class="wordsItemText" v-else>语速：{{ parseInt(charCountNum / (videoDuration / 60)) }}字/分钟</div>
                </div>
                <div class="indiciaBtn" @click="analysisChar = true" v-if="!analysisChar">点击分析</div>
                <div class="indiciaBtn" @click="analysisChar = false" v-if="analysisChar">隐藏分析</div>
            </div>
            <div class="wordsItemContainer">
                <div style="color: #0077FF; cursor: pointer;" @click="shareAnalysis"
                    v-if="(videoInfo && videoInfo.UploadStatus === 0) || (fileInfo && fileInfo.uploadStatus === 0)">分享复盘
                </div>
                <div style="color: #0077FF; cursor: pointer;" v-else @click="copyShareAnalysisLink">复制分享链接</div>
            </div>
        </div>

        <div class="videoAndWordContainer">
            <div class="videoAndTradeContainer" :style="'width: ' + videoWidth" v-if="sentenceMarkData.playUrl">
                <!-- 行业 -->
                <div>
                    <el-cascader v-model="tradeIdArr" :options="tradeTreeList" style="width: 100%"
                        :props="{ checkStrictly: true, expandTrigger: 'click', value: 'id', label: 'name' }" filterable
                        size="mini" @change="enoughMarkProperty(1)" ref="tradeCascader">
                    </el-cascader>
                </div>
                <!-- 视频/音频 -->
                <div class="videoContainer">
                    <video-player ref="videoPlayer" class="video" :options="playerOptions" :playsinline="true"
                        @timeupdate="onPlayerTimeupdate" @canplay="canplay"></video-player>
                </div>
            </div>
            <div class="discernContainer">
                <div class="discernSearchContainer">
                    <div v-if="!sentenceMarkData.playUrl" style="margin-right: 10px;">
                        <el-cascader v-model="tradeIdArr" :options="tradeTreeList" style="width: 220px"
                            :props="{ checkStrictly: true, expandTrigger: 'click', value: 'id', label: 'name' }"
                            filterable size="mini" @change="enoughMarkProperty(1)" ref="tradeCascader">
                        </el-cascader>
                    </div>
                    <!-- 搜索内容标注关键字 -->
                    <div style="display: flex; align-items: center;" v-if="searchWordIndexInfo.name">
                        <div style="font-size: 14px; color: #444;margin-right: 10px;">数量：{{ searchWordIndexInfo.count }}
                        </div>
                        <div v-if="searchWordIndexInfo.count > 0" style="display: flex; align-items: center;">
                            <i class="el-icon-caret-left" @click="prevSearchWords"
                                style="font-size: 26px; color: dodgerblue; cursor: pointer;"></i>
                            <i class="el-icon-caret-right" @click="nextSearchWords"
                                style="font-size: 26px; color: dodgerblue; cursor: pointer;"></i>
                        </div>
                    </div>
                    <el-input size="mini" placeholder="输入内容标注关键字" suffix-icon="el-icon-search"
                        v-model="searchWordIndexInfo.name" style="width: 200px;" @input="markKeywords" clearable>
                    </el-input>

                    <!-- 导出文字内容 -->
                    <el-button @click="exportTxt" style="margin-left: 10px;" size="mini" type="primary">导出文字</el-button>
                </div>
                <!-- 音频/视频文件的文字段落 -->
                <div class="wordsBodyContainer" v-if="sentenceMarkData.playUrl" :style="wordsBodyContainerHeight">
                    <div v-for="item in sentenceMarkList" class="wordsBodyItemContainer" ref="dom">
                        <img :src="anchorInfo.AnchorAvatar" class="wordsBodyAvatar"
                            v-if="anchorInfo && anchorInfo.AnchorAvatar">
                        <img src="@/assets/imgs/avatar.png" class="wordsBodyAvatar" v-else>
                        <div class="wordsBodyContentContainer">
                            <div style="display: flex; align-items: center;">
                                <div class="wordsBodyContentText1">说话人</div>
                                <div class="wordsBodyContentText2">{{ toformatTime(item.items[0].startTime) }}</div>
                                <div class="wordsBodyContentText2" v-if="analysisChar"> {{ " 语速：" +
                                    parseInt(item.charNumSecond)
                                    }}字/分钟</div>
                            </div>

                            <!-- 段落 -->
                            <div class="paragraphContainer">
                                <!-- 让两个元素重叠 -->
                                <div style="position: relative; z-index: -1;"
                                    v-html="'<span>' + item.searchMarkContent + '</span>'">
                                </div>
                                <div style="position: absolute; left: 0; top: 0; z-index: -2;"
                                    v-html="'<span>' + item.markContent + '</span>'">
                                </div>
                                <div style="position: absolute; left: 0; top: 0;">
                                    <span
                                        :class="(wordsItem.startTime < videoCurrentTime && videoCurrentTime < wordsItem.endTime)
                                            && (wordsItem.word != '，' && wordsItem.word != '。' && wordsItem.word != '！' && wordsItem.word != '？' && wordsItem.word != '、') ? 'wordTimeHover' : 'word'"
                                        v-for="(wordsItem, index) in item.items" :key="index"
                                        @click="playerReadied(wordsItem.startTime)">{{ wordsItem.word }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 文本文件的文字段落 -->
                <div class="wordsBodyContainer" v-else :style="wordsBodyContainerHeight">

                    <div v-for="item in sentenceMarkList" class="wordsBodyItemContainer" ref="dom">
                        <img :src="anchorInfo.AnchorAvatar" class="wordsBodyAvatar"
                            v-if="anchorInfo && anchorInfo.AnchorAvatar">
                        <img src="@/assets/imgs/avatar.png" class="wordsBodyAvatar" v-else>
                        <div class="wordsBodyContentContainer">
                            <div class="wordsBodyContentText1">说话人</div>
                            <!-- 段落 -->
                            <div class="paragraphContainer">
                                <div style="position: relative; z-index: 1;white-space: pre-wrap;margin-top: 10px;"
                                    v-html="'<span>' + item.searchMarkContent + '</span>'">
                                </div>
                                <div style="position: absolute; left: 0; top: 0; z-index: -1;white-space: pre-wrap;margin-top: 10px;"
                                    v-html="'<span>' + item.markContent + '</span>'">
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- 关键词/敏感词列表 -->
                <div class="wordsSummaryContainer" ref="wordsSummaryContainer"
                    v-if="wordTabelList && wordTabelList.length > 0 && (wordsInfo.markCrux || wordsInfo.markSensitive)">
                    <div class="wordsExportContainer">
                        <div>关键词汇总</div>
                        <!-- <div>
                            <el-button @click="exportExcel" style="margin-left: 10px;" size="mini"
                                type="primary">导出词语列表</el-button>
                        </div> -->
                    </div>
                    <el-table :data="wordTabelList" max-height="300" border style="width: 100%; margin-top: 6px;"
                        size="mini" :key="wordTabelRefresh">
                        <el-table-column prop="wordsTypeStr" label="类型" align="center">
                        </el-table-column>
                        <el-table-column prop="name" label="词语" align="center">
                        </el-table-column>
                        <el-table-column prop="countNum" label="次数" align="center" width="130">
                            <template slot-scope="scope">
                                <div style="display: flex; align-items: center;justify-content: space-between;">
                                    <i class="el-icon-caret-left" @click="prevWords(scope.row)"
                                        style="font-size: 22px; color: dodgerblue; cursor: pointer;"></i>
                                    <div
                                        v-if="wordIndexInfo.name == scope.row.name && wordIndexInfo.wordsType === scope.row.wordsType">
                                        {{ (wordIndexInfo.wordIndex +
                                            1) + "/" }}</div>
                                    <div>{{ scope.row.countNum }}</div>
                                    <i class="el-icon-caret-right" @click="nextWords(scope.row)"
                                        style="font-size: 22px; color: dodgerblue; cursor: pointer;"></i>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="resourceTypeStr" label="来源" align="center">
                        </el-table-column>
                        <el-table-column prop="remarks" label="场景描述" show-overflow-tooltip align="center">
                        </el-table-column>
                        <!-- <el-table-column prop="platformTypeStr" label="平台" align="center">
                        </el-table-column> -->
                        <el-table-column prop="tradeStr" label="行业" align="center">
                        </el-table-column>
                        <el-table-column prop="typeStr" label="分类" align="center">
                        </el-table-column>
                        <!-- <el-table-column prop="levelStr" label="等级" align="center">
                        </el-table-column> -->
                    </el-table>
                    <!-- 分页 -->
                    <div>
                        <el-pagination style="text-align: center; margin-top: 6px" @current-change="currentChangeHandle"
                            :current-page="pageIndex" :page-sizes="[8]" :page-size="pageSize" :total="totalCount"
                            layout="total, sizes, prev, pager, next, jumper">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="markDialogVisible" :show-close="false" width="30%" top="40vh">
            <div class="markDialogContainer">
                <div class="markDialogTitleContainer">
                    <img src="@/assets/imgs/close.png" class="markDialogCloseImg" @click="cancalMark">
                </div>
                <div v-if="sufficient">
                    <div class="markDialogBodyContainer">
                        <div class="markDialogBodyText1" v-if="duration">本视频共计{{ duration }}分钟</div>
                        <div class="markDialogBodyText1" v-if="wordNum">本文件共计{{ wordNum }}个字</div>
                        <div class="markDialogBodyText2" v-if="duration">标注敏感词将消耗资源，当前剩余资源：{{ userProperty.anchorNum
                            }}分钟，是否继续？
                        </div>
                        <div class="markDialogBodyText2" v-if="wordNum">标注敏感词将消耗资源，当前剩余资源：{{
                            userProperty.totalAiAnalysisTime
                        }}字，是否继续？</div>
                    </div>
                    <div class="markDialogBtnContainer">
                        <div class="markDialogBtn1" @click="cancalMark">取消</div>
                        <div class="markDialogBtn2" @click="confirmUseMark">继续</div>
                    </div>
                </div>
                <div v-else>
                    <div class="markDialogBodyContainer">
                        <div class="markDialogBodyText1" v-if="duration">本视频共计{{ duration }}分钟</div>
                        <div class="markDialogBodyText1" v-if="wordNum">本文件共计{{ wordNum }}个字</div>
                        <div class="markDialogBodyText2">当前流量包不足，还请充值后再使用</div>
                    </div>
                    <div class="markDialogBtnContainer">
                        <div class="markDialogBtn1" @click="cancalMark">知道了</div>
                        <div class="markDialogBtn2" @click="showCustomerServiceQrCode">
                            立即充值
                        </div>
                    </div>
                </div>
            </div>

        </el-dialog>

        <!-- 客服二维码 -->
        <customer-service-qr-code v-if="customerServiceQrCodeVisible"
            ref="customerServiceQrCode"></customer-service-qr-code>

    </div>
</template>

<script>
import myUtils from '../../utils/utils';
import CustomerServiceQrCode from './customerServiceQrCode';

export default {
    components: {
        CustomerServiceQrCode
    },
    props: {
        // 数据
        sentenceMarkData: {
            type: Object,
            required: true
        },
        // 视频播放器宽度，如：28%
        videoWidth: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            customerServiceQrCodeVisible: false, // 显示客服二维码弹窗
            markDialogVisible: false, // 标注资源确定弹窗
            pageSize: 8,
            totalCount: 0,
            pageIndex: 1,
            wordTabelList: [],
            videoCurrentTime: 0, // 播放器当前进度，毫秒
            // 播放器参数
            playerOptions: {
                // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                autoplay: false, // 如果true,浏览器准备好时开始播放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: true, // 循环播放
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: 'zh-CN',
                // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                    {
                        type: 'video/mp4', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
                        src: this.sentenceMarkData.playUrl // url地址
                    }
                ],
                hls: true,
                notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: false, // 当前时间和持续时间的分隔符
                    durationDisplay: false,  // 显示持续时间
                    remainingTimeDisplay: true, // // 是否显示剩余时间功能
                    fullscreenToggle: true, // 全屏按钮
                }
            },
            // 关键词/敏感词数据
            wordsInfo: {
                wordsList: [], // 关键词/敏感词列表
                cruxWordsNum: 0, // 关键词个数
                sensitiveWordsNum: 0, // 敏感词个数
                markCrux: true, // 是否标注关键词
                markSensitive: true, // 是否标注敏感词
            },
            sentenceMarkList: this.sentenceMarkData.sentenceMarkList,
            videoInfo: this.sentenceMarkData.videoInfo,
            anchorInfo: this.sentenceMarkData.anchorInfo,
            fileInfo: this.sentenceMarkData.fileInfo,
            wordsBodyContainerHeight: "",
            tradeTreeList: [],
            tradeIdArr: [],
            oldTradeIdArr: [],
            wordIndexInfo: {
                id: "",
                name: "",
                wordIndex: 0,
                paragraphIndex: 0,
                wordsType: "",
                recordNeedsWordNumList: [],
            },
            // 搜索标注关键字
            searchWordIndexInfo: {
                name: "",
                wordIndex: 0,
                paragraphIndex: 0,
                count: 0,
            },
            durationStr: "",
            currentParagraphIndex: 0, // 当前段落
            videoDuration: "", // 视频时长
            charCountNum: "", // 文字总数量
            analysisChar: false,  // 显示语速分析
            wordTabelRefresh: false, // 用于重新渲染词语列表
            isMark: 0, // 是否允许标注，0：否 1：是
            sufficient: false, // 资源是否足够
            duration: "", // 视频时长，单位：分钟
            wordNum: "", // 文字总数量
            userProperty: {}, // 用户资产信息
            markResourceType: "", // 标注的来源类型 0：初次标注 1：换行业标注
        };
    },

    mounted() {
        this.isMark = this.videoInfo && this.videoInfo.Id ? this.videoInfo.IsMark : this.fileInfo.isMark;
        if (this.isMark == 1) {
            this.wordsInfo.markCrux = true;
            this.wordsInfo.markSensitive = true;
        }

        this.getWordsBodyContainerHeight();
        this.getTradeTreeList();
        this.countWords();

        // 计算时长
        if (this.videoInfo && this.videoInfo.Duration) {
            this.durationStr = "";
            let arr = this.videoInfo.Duration.split(":");
            if (arr[0] && parseInt(arr[0])) {
                this.durationStr += arr[0] + "时";
            }
            if (arr[1] && parseInt(arr[1])) {
                this.durationStr += arr[1] + "分";
            }
        }
    },

    methods: {
        // 取消消耗资源标注
        cancalMark() {
            this.markDialogVisible = false;
            if (this.markResourceType == 1) {
                this.tradeIdArr = this.oldTradeIdArr;
                // 关闭级联列表下拉
                this.$refs.tradeCascader.dropDownVisible = false;
            }
        },
        // 使用资源
        confirmUseMark() {

            if (this.markResourceType == 1) {
                // 二次选择行业标注
                this.tradeChange();
                this.markDialogVisible = false;
            } else {
                // 首次标注
                if (this.videoInfo && this.videoInfo.VideoId) {
                    // 视频
                    let requestData = {
                        videoId: this.videoInfo.VideoId,
                        duration: this.duration
                    }
                    this.$httpClient.video.confirmUseMark(requestData).then(res => {
                        if (res.code == 0) {
                            this.$message.success("分析成功");
                            this.markDialogVisible = false;
                            this.isMark = 1;
                            this.wordsInfo.markCrux = true;
                            this.wordsInfo.markSensitive = true;
                            this.countWords();
                        }
                    })
                } else {
                    // 文件
                    let requestData = {
                        fileId: this.fileInfo.fileId,
                        duration: this.duration ? this.duration : 0,
                        wordNum: this.wordNum ? this.wordNum : 0
                    }

                    this.$httpClient.uploadFile.confirmUseMark(requestData).then(res => {
                        if (res.code == 0) {
                            this.$message.success("分析成功");
                            this.markDialogVisible = false;
                            this.isMark = 1;
                            this.wordsInfo.markCrux = true;
                            this.wordsInfo.markSensitive = true;
                            this.countWords();
                        }
                    })
                }
            }



        },
        // 显示客服二维码
        showCustomerServiceQrCode() {
            this.markDialogVisible = false
            this.customerServiceQrCodeVisible = true;
            this.$nextTick(() => {
                this.$refs.customerServiceQrCode.init();
            });
        },
        // 复制分享链接
        copyShareAnalysisLink() {
            this.$message.error("功能暂未开放");
        },
        // 分享复盘
        shareAnalysis() {
            if (this.videoInfo && this.videoInfo.VideoId) {
                // 视频
                this.$httpClient.video.shareAnalysis({ videoId: this.videoInfo.VideoId }).then(res => {
                    if (res.code == 0) {
                        this.$message.success("分享成功");
                        this.videoInfo.UploadStatus = 1;
                    }
                });
            } else {
                // 文件
                this.$httpClient.uploadFile.shareAnalysis({ fileId: this.fileInfo.fileId }).then(res => {
                    if (res.code == 0) {
                        this.$message.success("分享成功");
                        this.fileInfo.uploadStatus = 1;
                    }
                });
            }
        },
        // 初始化词语跳转信息
        initWordIndexInfo() {
            this.totalCount = 0;
            this.pageIndex = 1;
            this.wordIndexInfo = {
                name: "",
                wordIndex: 0,
                paragraphIndex: 0,
            };
            this.searchWordIndexInfo = {
                name: "",
                wordIndex: 0,
                paragraphIndex: 0,
                count: 0,
            };
        },
        // 视频加载完成回调事件
        canplay() {
            if (this.$refs.videoPlayer) {
                this.videoDuration = this.$refs.videoPlayer.player.duration();
                this.charCountNum = 0;
                // 计算语速
                if (this.sentenceMarkList && this.sentenceMarkList.length > 0) {
                    this.sentenceMarkList.forEach(item => {
                        let tempContent = item.content.replaceAll("，", "").replaceAll("。", "").replaceAll("？", "").replaceAll("、", "");
                        this.charCountNum += tempContent.length;
                        item.charNumSecond = tempContent.length / ((item.endTime - item.startTime) / 1000) * 60;
                    })
                }
            }

        },
        // 跳转到下一个词语
        nextWords(obj) {
            if (this.wordIndexInfo.name && this.wordIndexInfo.name == obj.name && this.wordIndexInfo.wordsType === obj.wordsType) {
                if (this.wordIndexInfo.wordIndex >= obj.countNum - 1) {
                    this.wordIndexInfo.wordIndex = 0;
                } else {
                    this.wordIndexInfo.wordIndex++;
                }
            } else {
                // 没有存在，从第一段开始找
                this.wordIndexInfo.id = obj.id;
                this.wordIndexInfo.name = obj.name;
                this.wordIndexInfo.wordIndex = 0;
                this.wordIndexInfo.wordsType = obj.wordsType;
                this.recordNeedsWordNumList = obj.recordNeedsWordNumList;
            }

            let oldParagraphIndex = this.wordIndexInfo.paragraphIndex;
            this.mark();
            this.$nextTick(() => {
                if (this.wordIndexInfo.paragraphIndex != oldParagraphIndex) {
                    this.$refs.dom[this.wordIndexInfo.paragraphIndex].scrollIntoView({ behavior: "smooth" });

                }
                if (this.$refs.videoPlayer) {
                    this.$refs.videoPlayer.player.pause();
                }

                this.wordTabelRefresh = !this.wordTabelRefresh;
            });
        },
        // 跳转到上一个词语
        prevWords(obj) {
            if (this.wordIndexInfo.name && this.wordIndexInfo.name == obj.name && this.wordIndexInfo.wordsType === obj.wordsType) {

                if (this.wordIndexInfo.wordIndex <= 0) {
                    this.wordIndexInfo.wordIndex = obj.countNum - 1;
                } else {
                    this.wordIndexInfo.wordIndex--;
                }
            } else {
                // 没有存在，从最后一段开始找
                this.wordIndexInfo.id = obj.id;
                this.wordIndexInfo.name = obj.name;
                this.wordIndexInfo.wordIndex = obj.countNum - 1;
                this.wordIndexInfo.wordsType = obj.wordsType;
                this.recordNeedsWordNumList = obj.recordNeedsWordNumList;
            }

            let oldParagraphIndex = this.wordIndexInfo.paragraphIndex;
            this.mark();
            this.$nextTick(() => {
                if (this.wordIndexInfo.paragraphIndex != oldParagraphIndex) {
                    this.$refs.dom[this.wordIndexInfo.paragraphIndex].scrollIntoView({ behavior: "smooth" });
                }
                if (this.$refs.videoPlayer) {
                    this.$refs.videoPlayer.player.pause();
                }

                this.wordTabelRefresh = !this.wordTabelRefresh;
            });
        },
        // 跳转到下一个搜索词
        nextSearchWords() {
            if (this.searchWordIndexInfo.wordIndex >= this.searchWordIndexInfo.count - 1) {
                this.searchWordIndexInfo.wordIndex = 0;
            } else {
                this.searchWordIndexInfo.wordIndex++;
            }
            let oldParagraphIndex = this.searchWordIndexInfo.paragraphIndex;
            this.mark(true);
            this.$nextTick(() => {
                if (oldParagraphIndex != this.searchWordIndexInfo.paragraphIndex) {
                    this.$refs.dom[this.searchWordIndexInfo.paragraphIndex].scrollIntoView({ behavior: "smooth" });
                    if (this.$refs.videoPlayer) {
                        this.$refs.videoPlayer.player.pause();
                    }
                }

            });
        },
        // 跳转到上一个搜索词
        prevSearchWords() {
            if (this.searchWordIndexInfo.wordIndex <= 0) {
                this.searchWordIndexInfo.wordIndex = this.searchWordIndexInfo.count - 1;
            } else {
                this.searchWordIndexInfo.wordIndex--;
            }
            let oldParagraphIndex = this.searchWordIndexInfo.paragraphIndex;
            this.mark(true);
            this.$nextTick(() => {
                if (oldParagraphIndex != this.searchWordIndexInfo.paragraphIndex) {
                    this.$refs.dom[this.searchWordIndexInfo.paragraphIndex].scrollIntoView({ behavior: "smooth" });
                    if (this.$refs.videoPlayer) {
                        this.$refs.videoPlayer.player.pause();
                    }
                }
            });
        },
        // 修改行业二次分析
        tradeChange() {
            // 初始化
            this.initWordIndexInfo();
            this.oldTradeIdArr = this.tradeIdArr;
            // 关闭级联列表下拉
            this.$refs.tradeCascader.dropDownVisible = false;

            if (this.videoInfo && this.videoInfo.VideoId) {
                // 录制的视频
                let requestData = {
                    videoId: this.videoInfo.VideoId,
                    tradeId: this.tradeIdArr[this.tradeIdArr.length - 1],
                    platformType: this.videoInfo.PlatformType,
                    duration: 0
                }
                this.$httpClient.video.reAnalysisByTrade(requestData).then(res => {
                    if (res.code == 0 && res.data) {
                        this.$message.success("分析成功");
                        // 重新赋值数据
                        this.sentenceMarkList = [];
                        // 视频/音频播放地址
                        this.sentenceMarkData.playUrl = res.data.playUrl;
                        // 视频信息
                        this.videoInfo = res.data.videoInfo;
                        if (this.videoInfo.Duration) {
                            this.videoInfo.Duration = myUtils.toformatTime(this.videoInfo.Duration * 1000);
                        }
                        // 主播信息
                        this.anchorInfo = res.data.anchorInfo;
                        // 段落
                        if (res.data.audioaAlyses && res.data.audioaAlyses.length > 0) {
                            // 添加进数组并排序
                            res.data.audioaAlyses.forEach(item => {
                                if (item.Status == 0) {
                                    let obj = JSON.parse(item.DataJson);
                                    obj.markContent = item.content;
                                    obj.searchMarkContent = item.content;
                                    this.sentenceMarkList.push(obj);
                                }
                            });
                            this.sentenceMarkList.sort((a, b) => a.currentSort - b.currentSort);

                            // 设置段落的开始时间和结束时间
                            if (this.sentenceMarkList.length > 0) {
                                this.sentenceMarkList.forEach((item, index) => {
                                    item.markContent = item.content;
                                    item.searchMarkContent = item.content;
                                    if (index == 0) {
                                        item.startTime = 0;
                                    } else {
                                        item.startTime = item.items[0].startTime;
                                    }
                                    item.endTime = item.items[item.items.length - 1].endTime;
                                })
                            }
                        }
                        this.countWords();
                        this.canplay();
                    }
                });
            } else {
                // 上传的文件
                let requestData = {
                    fileId: this.fileInfo.fileId,
                    tradeId: this.tradeIdArr[this.tradeIdArr.length - 1],
                    platformType: this.fileInfo.platformType,
                    duration: 0,
                    wordNum: 0
                }
                this.$httpClient.uploadFile.reAnalysisByTrade(requestData).then(res => {
                    if (res.code == 0 && res.data) {
                        this.$message.success("分析成功");
                        // 重新赋值数据
                        this.sentenceMarkList = [];
                        // 视频/音频播放地址
                        this.sentenceMarkData.playUrl = res.data.playUrl;
                        // 文件信息
                        this.fileInfo = res.data.uploadFile;
                        if (this.fileInfo.fileDuration) {
                            this.fileInfo.fileDuration = myUtils.toformatTime(this.fileInfo.fileDuration * 1000);
                        }
                        // 段落
                        if (res.data.fileAudioaAlyses && res.data.fileAudioaAlyses.length > 0) {
                            // 添加进数组并排序
                            res.data.fileAudioaAlyses.forEach(item => {
                                if (item.status == 0) {
                                    let obj = JSON.parse(item.dataJson);
                                    obj.markContent = item.content;
                                    obj.searchMarkContent = item.content;
                                    this.sentenceMarkList.push(obj);
                                }
                            });
                            this.sentenceMarkList.sort((a, b) => a.currentSort - b.currentSort);

                            // 设置段落的开始时间和结束时间(文本文件不设置)
                            if (this.sentenceMarkList.length > 0 && this.fileInfo.fileType != 2) {
                                this.sentenceMarkList.forEach((item, index) => {
                                    item.markContent = item.content;
                                    item.searchMarkContent = item.content;
                                    if (index == 0) {
                                        item.startTime = 0;
                                    } else {
                                        item.startTime = item.items[0].startTime;
                                    }
                                    item.endTime = item.items[item.items.length - 1].endTime;
                                })
                            }
                        }
                        this.countWords();
                        this.canplay();
                    }
                });
            }

        },
        // 获取行业列表树形
        getTradeTreeList() {
            this.tradeTreeList = [];
            this.$httpBack.trade.listTree({}).then((res) => {
                if (res && res.code === 0) {
                    this.tradeTreeList = res.data;
                    this.tradeIdArr = this.videoInfo && this.videoInfo.VideoId ? this.videoInfo.TradeId : this.fileInfo.tradeId;
                    this.oldTradeIdArr = this.tradeIdArr;
                }
            });
        },
        // 导出词语列表excel
        exportExcel() {
            let requestData = {
                dataJson: encodeURIComponent(JSON.stringify(this.wordsInfo.wordsList)),
                type: this.videoInfo && this.videoInfo.VideoId ? 0 : 1,
                id: this.videoInfo && this.videoInfo.VideoId ? this.videoInfo.VideoId : this.fileInfo.fileId
            }
            this.$httpClient.export.wordsexcel(requestData).then(res => {
                if (res.code == 0 && res.data) {
                    this.$message.success("导出成功");
                }
            })
        },
        // 切换分页回调
        currentChangeHandle(val) {
            this.pageIndex = val;
            this.addWordTabelList();
        },
        // 将词语列表分页添加到表格
        addWordTabelList() {
            this.wordTabelList = [];

            if (this.wordsInfo.wordsList && this.wordsInfo.wordsList.length > 0) {
                let tempArr = [];
                this.wordsInfo.wordsList.forEach(item => {
                    if (this.wordsInfo.markCrux && item.wordsType == 1) {
                        tempArr.push(item);
                    }
                    if (this.wordsInfo.markSensitive && item.wordsType == 0) {
                        tempArr.push(item);
                    }
                });


                if (tempArr.length > 0) {
                    let startIndex = this.pageSize * (this.pageIndex - 1);
                    let endIndex = startIndex + this.pageSize;
                    for (let i = startIndex; i < endIndex; i++) {
                        if (tempArr[i]) {
                            this.wordTabelList.push(tempArr[i]);
                        }
                    }
                }
                this.totalCount = tempArr.length;

                this.getWordsBodyContainerHeight();


            }

        },
        // 计算文本段落区域的高度
        getWordsBodyContainerHeight() {
            this.$nextTick(() => {
                let wordsSummaryContainerHeight = "0px";
                if (this.wordTabelList.length > 0) {
                    wordsSummaryContainerHeight = this.$refs.wordsSummaryContainer.offsetHeight + "px";
                }
                this.wordsBodyContainerHeight = { "max-height": `calc(100vh - 240px - ${wordsSummaryContainerHeight})` };
            });
        },
        // 导出文字内容
        exportTxt() {
            let requestData = {
                type: this.videoInfo && this.videoInfo.VideoId ? 0 : 1,
                id: this.videoInfo && this.videoInfo.VideoId ? this.videoInfo.VideoId : this.fileInfo.fileId,
                fileName: encodeURIComponent(this.videoInfo && this.videoInfo.VideoId ? this.videoInfo.VideoName : this.fileInfo.fileName),
            }
            this.$httpClient.export.alysestxt(requestData).then(res => {
                if (res.code == 0 && res.data) {
                    this.$message.success("导出成功");
                }
            });
        },
        // 判断是否有足够的标注资源，resourceType 来源类型 0：初次标注 1：换行业标注
        enoughMarkProperty(markResourceType) {

            this.tradeChange();

            // this.markResourceType = markResourceType;

            // this.$httpClient.userProperty.info({}).then(res => {
            //     this.userProperty = res.data;

            //     if ((this.videoInfo && this.videoInfo.VideoId) || (this.fileInfo && (this.fileInfo.fileType == 0 || this.fileInfo.fileType == 1))) {
            //         // 按时长计费
            //         console.log(this.fileInfo)
            //         let duration = 0; // 时长，分钟
            //         if (this.videoInfo && this.videoInfo.VideoId) {
            //             duration = Math.floor(myUtils.toSecond(this.videoInfo.Duration) / 60);

            //         } else {
            //             duration = Math.floor(myUtils.toSecond(this.fileInfo.fileDuration) / 60);
            //         }
            //         duration = duration < 1 ? 1 : duration;
            //         this.duration = duration;

            //         console.log(duration)
            //     } else {
            //         // 按文本字数计费
            //         let wordNum = this.fileInfo.fileWordNum;
            //         this.wordNum = wordNum;
            //     }

            //     this.sufficient = true;
            //     this.markDialogVisible = true;
            // });
        },
        // 标注/取消标注按钮回调
        markClick(type, flag) {

            // if (this.isMark == 0) {
            //     // 不允许标注，判断资源是否足够
            //     this.enoughMarkProperty(0);
            //     return;
            // }

            if (type == "sensitive") {
                // 敏感词
                this.wordsInfo.markSensitive = flag;
            } else if (type == "crux") {
                // 关键词
                this.wordsInfo.markCrux = flag;
            }
            this.$emit("countWords", JSON.parse(JSON.stringify(this.wordsInfo)));
            this.mark();
            this.pageIndex = 1;
            this.addWordTabelList();
        },
        // 搜索关键字
        markKeywords() {
            this.mark();
            this.$nextTick(() => {
                if (this.searchWordIndexInfo.name) {
                    // 跳转到第一个搜索词所在的段落
                    let index = -1;
                    for (let i = 0; i < this.sentenceMarkList.length; i++) {
                        let item = this.sentenceMarkList[i];
                        if (item.content.indexOf(this.searchWordIndexInfo.name) != -1) {
                            index = i;
                            break;
                        }
                    }
                    if (index != -1) {
                        this.$refs.dom[index].scrollIntoView({ behavior: "smooth" });
                        if (this.$refs.videoPlayer) {
                            this.$refs.videoPlayer.player.pause();
                        }
                    }
                }
            })

        },
        // 标注段落的关键词
        mark(isSearch) {
            this.searchWordIndexInfo.count = 0;
            if (this.sentenceMarkList) {
                let wordIndex = 0;
                this.sentenceMarkList.forEach((sentenceItem, index) => {
                    sentenceItem.markContent = sentenceItem.content;
                    sentenceItem.searchMarkContent = sentenceItem.content;

                    if (this.wordsInfo.wordsList.length > 0) {
                        // 按词语长度倒序排序，保证被覆盖的词语也能正确标注
                        let tempWordsList = JSON.parse(JSON.stringify(this.wordsInfo.wordsList));
                        tempWordsList.sort((a, b) => b.name.length - a.name.length);

                        // 标注段落中的敏感词
                        if (this.wordsInfo.markSensitive) {
                            tempWordsList.forEach(wordItem => {
                                if (wordItem.wordsType == 0 && sentenceItem.content.indexOf(wordItem.name) != -1) {
                                    // 获取当前段落存在词语的数量
                                    const regex = new RegExp(wordItem.name, 'g');
                                    let count = sentenceItem.markContent.match(regex) ? sentenceItem.markContent.match(regex).length : 0;
                                    // 遍历段落中的每个词语，判断是否需要标注
                                    let wordCurrentINdex = 0;
                                    for (let i = 0; i < count; i++) {
                                        wordCurrentINdex = wordCurrentINdex == 0 ? 0 : wordCurrentINdex + wordItem.name.length;
                                        wordCurrentINdex = sentenceItem.markContent.indexOf(wordItem.name, wordCurrentINdex);

                                        if (wordItem.recordNeedsWordNumList.includes(i)) {
                                            let leftContent = sentenceItem.markContent.substring(0, wordCurrentINdex);
                                            let rightContent = sentenceItem.markContent.substring(wordCurrentINdex + wordItem.name.length);
                                            let markWordContent = "<span style='background: pink'>" + wordItem.name + "</span>";

                                            sentenceItem.markContent = leftContent + markWordContent + rightContent;
                                            wordCurrentINdex = wordCurrentINdex + markWordContent.length - wordItem.name.length;
                                        }
                                    }
                                }
                            })
                        }

                        // 标注段落中的关键词
                        if (this.wordsInfo.markCrux) {
                            tempWordsList.forEach(wordItem => {
                                if (wordItem.wordsType == 1 && sentenceItem.content.indexOf(wordItem.name) != -1) {
                                    // 获取当前段落存在词语的数量
                                    const regex = new RegExp(wordItem.name, 'g');
                                    let count = sentenceItem.markContent.match(regex) ? sentenceItem.markContent.match(regex).length : 0;
                                    // 遍历段落中的每个词语，判断是否需要标注
                                    let wordCurrentINdex = 0;
                                    for (let i = 0; i < count; i++) {
                                        wordCurrentINdex = wordCurrentINdex == 0 ? 0 : wordCurrentINdex + wordItem.name.length;
                                        wordCurrentINdex = sentenceItem.markContent.indexOf(wordItem.name, wordCurrentINdex);

                                        if (wordItem.recordNeedsWordNumList.includes(i)) {
                                            let leftContent = sentenceItem.markContent.substring(0, wordCurrentINdex);
                                            let rightContent = sentenceItem.markContent.substring(wordCurrentINdex + wordItem.name.length);
                                            let markWordContent = "<span style='background: paleturquoise'>" + wordItem.name + "</span>";

                                            sentenceItem.markContent = leftContent + markWordContent + rightContent;
                                            wordCurrentINdex = wordCurrentINdex + markWordContent.length - wordItem.name.length;
                                        }
                                    }
                                }
                            })
                        }

                    }

                    // 标注搜索词
                    if (this.searchWordIndexInfo.name) {
                        sentenceItem.searchMarkContent = sentenceItem.searchMarkContent.replace(new RegExp(this.searchWordIndexInfo.name, 'g'), "<span style='background: darkorange'>" + this.searchWordIndexInfo.name + "</span>");
                        // 累计搜索词的数量
                        const regex = new RegExp(this.searchWordIndexInfo.name, 'g');
                        let count = sentenceItem.searchMarkContent.match(regex) ? sentenceItem.searchMarkContent.match(regex).length : 0;
                        this.searchWordIndexInfo.count += count;
                    }

                    if (isSearch) {
                        // 标注上一个/下一个搜索词
                        const regex = new RegExp(this.searchWordIndexInfo.name, 'g');
                        let count = sentenceItem.searchMarkContent.match(regex) ? sentenceItem.searchMarkContent.match(regex).length : 0;

                        for (let i = 0; i < count; i++) {
                            if (wordIndex == this.searchWordIndexInfo.wordIndex) {
                                let text = sentenceItem.searchMarkContent;
                                // 找到第i个词的索引
                                let searchStartIndex = 0;
                                for (let j = 0; j <= i; j++) {
                                    if (j == 0) {
                                        // 从第0个字符开始找
                                        searchStartIndex = text.indexOf(this.searchWordIndexInfo.name, 0);
                                    } else {
                                        // 从上次索引+词语长度开始找
                                        searchStartIndex = text.indexOf(this.searchWordIndexInfo.name, searchStartIndex + this.searchWordIndexInfo.name.length);
                                    }
                                }

                                // 拼接标注颜色
                                sentenceItem.searchMarkContent = text.substring(0, searchStartIndex);
                                sentenceItem.searchMarkContent += "<span style='background:indianred'>" + this.searchWordIndexInfo.name + "</span>";
                                sentenceItem.searchMarkContent += text.substring(searchStartIndex + this.searchWordIndexInfo.name.length);

                                // 要跳转的段落索引
                                this.searchWordIndexInfo.paragraphIndex = index;
                            }
                            wordIndex++;
                        }
                    } else if (this.wordIndexInfo.name) {
                        // 标注上一个/下一个词语
                        // 计算当前段落出现词语的次数
                        const regex = new RegExp(this.wordIndexInfo.name, 'g');
                        let count = sentenceItem.searchMarkContent.match(regex) ? sentenceItem.searchMarkContent.match(regex).length : 0;

                        // 遍历当前段落当前词语需要标注的列表
                        let recordNeedsWordNumList = [];
                        sentenceItem.wordsList.forEach(item => {
                            if (item.name == this.wordIndexInfo.name && item.wordsType === this.wordIndexInfo.wordsType) {
                                recordNeedsWordNumList = item.recordNeedsWordNumList;
                            }
                        })

                        for (let i = 0; i < count; i++) {
                            if (recordNeedsWordNumList.includes(i)) {
                                if (wordIndex == this.wordIndexInfo.wordIndex) {
                                    let text = sentenceItem.searchMarkContent;
                                    // 找到第i个词的索引
                                    let searchStartIndex = 0;
                                    for (let j = 0; j <= i; j++) {
                                        if (j == 0) {
                                            // 从第0个字符开始找
                                            searchStartIndex = text.indexOf(this.wordIndexInfo.name, 0);
                                        } else {
                                            // 从上次索引+词语长度开始找
                                            searchStartIndex = text.indexOf(this.wordIndexInfo.name, searchStartIndex + this.wordIndexInfo.name.length);
                                        }
                                    }

                                    // 拼接标注颜色
                                    sentenceItem.searchMarkContent = text.substring(0, searchStartIndex);
                                    sentenceItem.searchMarkContent += "<span style='background:limegreen'>" + this.wordIndexInfo.name + "</span>";
                                    sentenceItem.searchMarkContent += text.substring(searchStartIndex + this.wordIndexInfo.name.length);

                                    // 要跳转的段落索引
                                    this.wordIndexInfo.paragraphIndex = index;
                                }
                                wordIndex++;
                            }

                        }

                    }


                })

                this.sentenceMarkList = JSON.parse(JSON.stringify(this.sentenceMarkList));
            }



        },
        // 整理关键词、敏感词数据
        countWords() {
            this.wordsInfo.wordsList = [];
            this.wordsInfo.cruxWordsNum = 0;
            this.wordsInfo.sensitiveWordsNum = 0;

            if (this.sentenceMarkList && this.sentenceMarkList.length > 0) {

                this.sentenceMarkList.forEach(item => {
                    let wordsList = item.wordsList;
                    if (wordsList && wordsList.length > 0) {

                        wordsList.forEach(wordsItem => {
                            if (this.wordsInfo.wordsList.length < 1) {
                                this.wordsInfo.wordsList.push(wordsItem);
                            } else {
                                // 判断数组里面是否已经存在当前关键词/敏感词，如果是，则只增加出现数量
                                let exist = false;
                                this.wordsInfo.wordsList.forEach(haveItem => {
                                    if (haveItem.name == wordsItem.name && haveItem.wordsType == wordsItem.wordsType) {
                                        exist = true;
                                        haveItem.countNum += wordsItem.countNum;
                                    }
                                });
                                if (!exist) {
                                    this.wordsInfo.wordsList.push(wordsItem);
                                }
                            }
                        })
                    }
                });

            }

            if (this.wordsInfo.wordsList.length > 0) {
                this.wordsInfo.wordsList.forEach(wordsItem => {
                    // 统计数量
                    if (wordsItem.wordsType == 0) {
                        // 敏感词
                        this.wordsInfo.sensitiveWordsNum += wordsItem.countNum;
                    } else if (wordsItem.wordsType == 1) {
                        // 关键词
                        this.wordsInfo.cruxWordsNum += wordsItem.countNum;
                    }
                })
            }

            // 排序
            if (this.wordsInfo.wordsList) {
                this.wordsInfo.wordsList.sort((a, b) => b.countNum - a.countNum);
            }

            this.mark();
            this.addWordTabelList();
            this.$emit("countWords", JSON.parse(JSON.stringify(this.wordsInfo)));
        },
        // 设置播放器进度，秒
        playerReadied(second) {
            if (this.$refs.videoPlayer) {
                this.$refs.videoPlayer.player.currentTime(second / 1000);
                this.$refs.videoPlayer.player.play();
            }

        },
        // 播放器进度回调
        onPlayerTimeupdate(player) {

            this.videoCurrentTime = player.cache_.currentTime * 1000;

            // 滚动滚动条
            if (this.sentenceMarkList && this.sentenceMarkList.length > 0) {
                // 获取当前正在播放的段落索引
                let currentIndex = 0;

                let setFlag = false;
                for (let index = 0; index < this.sentenceMarkList.length; index++) {

                    let item = this.sentenceMarkList[index];
                    let nextItem = index + 1 == this.sentenceMarkList.length ? null : this.sentenceMarkList[index + 1];

                    if (nextItem) {
                        if (item.startTime < this.videoCurrentTime && this.videoCurrentTime < nextItem.startTime) {
                            currentIndex = index;
                            setFlag = true;
                        }
                    }

                }

                if (!setFlag) {
                    currentIndex = this.sentenceMarkList.length - 1;
                }

                this.sentenceMarkList.forEach((item, index) => {
                    if (item.startTime < this.videoCurrentTime && this.videoCurrentTime < item.endTime) {
                        currentIndex = index;
                    }
                });

                // 滚动滚动条到指定的段落元素
                // currentIndex -= 1;
                if (this.currentParagraphIndex != currentIndex) {
                    if (currentIndex < 0) {
                        this.$refs.dom[0].scrollIntoView({ behavior: "smooth" });
                    } else {
                        this.$refs.dom[currentIndex].scrollIntoView({ behavior: "smooth" });
                    }
                    this.currentParagraphIndex = currentIndex;
                }

            }
        },
        // 毫秒时间戳转成时分秒格式
        toformatTime(val) {
            return myUtils.toformatTime(val);
        },
    },
};
</script>
<style scoped>
.markDialogBtn2 {
    width: 110px;
    height: 40px;
    background: #5F3A00;
    border-radius: 4px;
    margin-left: 20px;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.markDialogBtn1 {
    width: 110px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #B26D00;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.markDialogBtnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}

.markDialogBodyText2 {
    margin-top: 10px;
}

.markDialogBodyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.markDialogCloseImg {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.markDialogTitleContainer {
    display: flex;
    width: 100%;
    justify-content: end;
    padding: 14px;
}

.markDialogContainer {
    background: linear-gradient(#FFEED9, #FFFCF9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 39px;
}

/deep/ .el-dialog__body {
    padding: 0;
}

/deep/ .el-dialog__header {
    display: none;
}

/deep/ .el-table--mini .el-table__cell {
    padding: 0;
}

/deep/ .el-table .el-table__cell {
    padding: 0;
}

.wordsExportContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.border-right {
    border-right: 0.5px solid #ccc;
}

.border-left {
    border-left: 0.5px solid #ccc;
}

.border-bottom {
    border-bottom: 0.5px solid #ccc;
}

.border-top {
    border-top: 0.5px solid #ccc;
}

.wordsSummaryTitleItem {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F7F9;
    font-size: 13px;
    font-weight: 400;
    color: #2E3742;
    height: 28px;
}

.wordsSummaryContentItem {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: #2E3742;
    height: 24px;
}

.wordsSummaryContentItemContainer {
    display: flex;
    align-items: center;
}

/* .wordsSummaryContentContainer::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

.wordsSummaryContentContainer::-webkit-scrollbar {
    width: 4px;
} */

.wordsSummaryContentContainer {
    /* overflow-y: auto; */
}

.wordsSummaryTitleContainer {
    display: flex;
    align-items: center;
}

.wordsSummaryBodyContainer {
    margin-top: 6px;
}

.wordsSummaryContainer {
    padding-top: 6px;
    font-weight: 600;
    font-size: 14px;
    color: #2E3742;
    border-top: 0.5px solid #eee;
}

.videoAndTradeContainer {
    display: flex;
    flex-direction: column;

}

.videoAndWordContainer {
    display: flex;
    margin-top: 12px;

}

.rightBorder {
    border-right: 0.5px solid#DCE0E7;
}

.discernSearchContainer {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.wordTimeHover {
    cursor: pointer;
    background: rgb(51, 109, 244);
    border-radius: 4px;
    color: #FFF;
    z-index: 99;
}

.word {
    cursor: pointer;
    background: transparent;
    border-radius: 0px;
    color: #2E3742;
}

.paragraphContainer {
    /* font-weight: 400; */
    font-size: 14px;
    color: #2E3742;
    margin-top: 6px;
    position: relative;
    line-height: 19px;
    letter-spacing: 0px;
}

.wordsBodyContentText2 {
    font-weight: 400;
    font-size: 12px;
    color: #95A1AF;
    margin-left: 4px;
    margin-top: 8px;
}

.wordsBodyContentText1 {
    font-weight: 400;
    font-size: 13px;
    color: #2E3742;
    margin-top: 8px;
}

.wordsBodyContentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 8px;
    flex-grow: 1;
}

.wordsBodyAvatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 0.5px #ccc solid;
}

.wordsBodyItemContainer {
    margin-bottom: 12px;
    display: flex;
    word-wrap: break-word;
    word-break: break-all;
}

.wordsBodyContainer::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

.wordsBodyContainer::-webkit-scrollbar {
    width: 4px;
}

.wordsBodyContainer {
    max-height: calc(100vh - 240px - 300px);
    overflow-y: auto;
    box-sizing: border-box;
}

.discernContainer::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

.discernContainer::-webkit-scrollbar {
    width: 4px;
}

.discernContainer {
    margin-left: 12px;
    box-sizing: border-box;
    flex-grow: 1;
    width: 0;
    /* overflow-y: auto;
    height: calc(100vh - 220px); */
}

/deep/ .vjs-play-control {
    display: none;
}

/deep/ .video-js .vjs-big-play-button {
    top: calc(50% - 22px);
    left: calc(50% - 42px);
}

.video {
    width: calc(100% - 0.5px);
}

.videoContainer {
    background-color: #fff;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-top: 6px;
}

.videoAnalysisContainer {
    display: flex;
    margin-top: 8px;
    flex-grow: 1;
    /* min-height: calc(100% - 191px); */
}

.cancelIndiciaBtn {
    width: 72px;
    height: 22px;
    border-radius: 4px;
    border: 1px solid #B4BCCA;
    font-size: 13px;
    color: #2E3742;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    cursor: pointer;
}

.indiciaBtn {
    width: 72px;
    height: 22px;
    border-radius: 4px;
    border: 1px solid #0077FF;
    font-size: 13px;
    color: #0077FF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    cursor: pointer;
}

.wordsItemText {
    font-weight: 500;
    font-size: 13px;
    color: #2E3742;
    margin-left: 4px;
}

.wordsItemColor {
    width: 24px;
    height: 16px;
    border-radius: 4px;
}

.wordsItemColorContainer {
    display: flex;
    align-items: center;
}

.wordsItemContainer {
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.wordsContainer {
    height: 46px;
    background: #F5F7F9;
    border-radius: 4px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
}

.analysisFileRightItemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
}

.analysisFileRightContainer {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 13px;
    color: #2E3742;
}

.analysisCompereName {
    color: #2E3742;
    font-size: 14px;
    margin-left: 10px;
    max-width: 300px;
}

.analysisCompereImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0.5px #ccc solid;
}

.analysisFileLeftContainer {
    display: flex;
    align-items: center;
}

.analysisFileContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}
</style>