<template>
    <el-dialog :visible.sync="visible" width="26%" top="45vh">
        <div class="customerServiceContainer">
            <div class="customerServiceTitle">请扫码添加企业微信沟通</div>
            <img :src="imgInfo.url" class="codeImg">
        </div>
    </el-dialog>

</template>

<script>
export default {

    data() {
        return {
            visible: false,
            imgInfo: {},
        };
    },

    mounted() {
        this.getCodeImg();
    },

    methods: {
        init() {
            this.visible = true;
        },
        getCodeImg() {
            this.imgInfo = {};
            this.$httpBack.file.showOne({ resourceType: 5 }).then((res) => {
                if (res.code == 0) {
                    this.imgInfo = res.data;
                }
            })
        }
    },
};
</script>
<style scoped>
.codeImg {
    width: 128px;
    height: 128px;
    margin-top: 16px;
}

.customerServiceTitle {
    font-weight: 600;
    font-size: 16px;
    color: #2E3742;
}

.customerServiceContainer {
    padding: 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>